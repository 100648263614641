@import '../../styles/variables';

.content-templates-page {
  height: 100%;
  border-left: 1px solid $neutral-color-100;
  overflow: hidden;
  .app-frame{
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    border: none;
  }
}
